$font_stack_1: 'Lora', serif;
$font_stack_2: 'Cardo', serif;

$white_color: #fff;
$black_color: #000;
$menu_color: #2a2a2a;
$icon_color: #ff7e5f;
$span_color: #0a0a0a;
$media_border: #e9ecee;

$btn_bg: #fc4600;
$btn_hover: #fc4600;
$base_color: #fc4600;
$section_bg: #f7f7f7;
$section_bg_1: #454545;
$heading_color: #2a2a2a;
$heading_color2: #ff8b23;
$p_color: #666666;
$font_1: #888888;
$font_2: #646464;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_6: #666666;
$border_color: #fdcb9e;
$footer_bg: #111516;
$sidebar_bg: #fbf9ff;



$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$small_mobile:'(max-width: 576px)';
$xs_mobile:'(max-width: 420px)';
$tab:'(max-width: 991px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: '(min-width: 1200px)';
  
  
  