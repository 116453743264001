/**************** feature_post css start ****************/
.all_post{
    .sidebar_widget{
        h3{
            font-size: 20px;
        }
        .btn_1{
            display: block;
            text-align: center;
            margin-top: 15px;
        }
        .form-group{
            margin-bottom: 0;
        }

        .single_sidebar_wiget{
            background-color: #fbf2ee;
            padding: 30px;
            margin-bottom: 30px;
            @media #{$tab}{
                padding: 20px 15px 15px;
            }
            @media #{$medium_device}{
                padding: 20px 15px 15px;
            }
        }
        .sidebar_tittle{
            h3{
                font-size: 24px;
                color: $menu_color;
                font-weight: 600;
                margin-bottom: 27px;
                position: relative;
                
                &:after{
                    position: absolute;
                    content: "";
                    width: 60px;
                    height: 2px;
                    bottom: 12px;
                    background-color: #fc4600;
                    left: 200px;
                    @media #{$medium_device}{
                        left: 170px;
                        width: 40px;
                    }
                }
                @media #{$tab}{
                    margin-bottom: 20px;
                }
                
            }
        }
        .form-control{
            border-radius: 0px !important;
            font-size: 13px;
            color: #999999;
            font-weight: 400;
            height: 52px;
            &:focus{
                outline: none !important;
                border-color: #e9ecee;
            }
        }
        .btn{
            background-color: $btn_bg;
            color: $white_color;
            width: 50px;
            margin-left: 10px;
            border-radius: 0px;
        }
       
    }
    .category{
        ul li{
            margin-bottom: 12px;
            font-size: 18px;
            background-color: $white_color;
            display: flex;
            justify-content: space-between;
            padding: 12px 20px;
            @include transform_time(0.5s);
            &:last-child{
                margin-bottom: 0;
            }
            a, span{
                color: #888888;
                font-family: $font_stack_2;
                &:hover{
                    color: #fff !important;
                }
            }
            &:hover{
                background-color: $base_color;
                @include transform_time(0.5s);
                a, span{
                    color: #fff;
                }
            }
        }
    }
    .tags{
        
        ul li{
            display: inline-block;
            a{
                display: inline-block;
                border: 1px solid transparent;
                background: #fff;
                margin-bottom: 8px;
                margin-right: 13px;
                transition: all 0.3s ease 0s;
                color: #888888;
                font-size: 13px;
                font-family: $font_stack_2;
                font-weight: 600;
                text-transform: uppercase;
                color: #2a2a2a;
                width: 45px;
                height: 45px;
                text-align: center;
                line-height: 45px;
                @include transform_time(.5s);
                &:hover{
                    border: 1px solid $btn_bg;
                    background-color: $btn_bg;
                    color: $white_color !important;
                }
                i{

                }
            }
        }
        
    }
    .load_btn {
        margin-top: 80px !important;
        @media #{$small_mobile}{
             margin-top: 30px !important;
        }
        @media #{$large_mobile}{
            margin-top: 30px !important;
        }
        @media #{$tab_device}{
        
        }
        @media #{$medium_device}{
        
        }
    }
    @media #{$small_mobile}{
        .sidebar_widget{
            margin-top: 50px;
        }
        .post_3{
            margin-bottom: 20px;

        }
    }
    @media #{$large_mobile}{
        .sidebar_widget{
            margin-top: 50px;
        }
        .post_3{
            margin-bottom: 20px;

        }
    }
    @media #{$tab_device}{
    .sidebar_widget{
            margin-top: 50px;
        }
        .post_3{
            margin-bottom: 20px;

        }
    }
    @media #{$medium_device}{
    
    }
}
.single_catagory_post{
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0px;
    }
}
.archive_post{
    padding: 100px 0px 65px;
    @media #{$small_mobile}{
        padding: 70px 0px 35px;
    }
    @media #{$large_mobile}{
        padding: 70px 0px 35px;
    }
    @media #{$tab_device}{
        padding: 80px 0px 45px;
    }
    @media #{$medium_device}{
    
    }
    .post_2 .post_text_1 {
        margin: 35px 0px;
    }
}
.blog_area {
    .load_btn{
        margin-top: 60px !important;
    }
 }