/* Main Button Area css
============================================================================================ */
.submit_btn{
	width: auto;
	display: inline-block;
	background: $white_color;
	padding: 0px 50px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 50px;
	border-radius: 5px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	border: 1px solid $border_color;
	cursor: pointer;
	@include transform_time(0.5s);
	&:hover{
		background: transparent;
		
	}
}
.btn_1{
	display: inline-block;
	padding: 14.5px 58px;
	font-size: 14px;
	color: $white_color;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
	text-transform: capitalize;
	background-color: $btn_bg;
	margin: 0 auto;
	&:hover{
		color: $white_color !important;
		background-color: #2a2a2a;

	}
	i{
		font-size: 12px;
		padding-left: 10px;
	}
}
/*=================== custom button rule start ====================*/

.button{
	display: inline-block;
	border: 1px solid transparent;
	font-size: 15px;
	font-weight: 500;
	padding: 12px 54px;
	border-radius: 4px;
	color: $white_color;
	border: 1px solid $border_color;
	text-transform: uppercase;
	background-color: $btn_bg;
	cursor: pointer;
	@include transform_time(0.5s);

	@media(max-width: 767px){
		font-size: 13px;
		padding: 9px 24px;
	}

	&:hover{
		color: $white_color;
	}


	&-link{
		letter-spacing: 0;
		color: #3b1d82;
		border: 0;
		padding: 0;

		&:hover{
			background: transparent;
			color: #3b1d82;
		}
	}

	&-header{
		color: $white_color;
		border-color: $border_color;

		&:hover{
			background: #b8024c;
			color: $white_color;
		}
	}

	&-contactForm{
		color: $white_color;
		border-color: $border_color;
		padding: 12px 25px;

		&:hover{
			// border-color: $title-color;
			// background: $title-color;
			// color: $white_color;
		}
	}
}


/* End Main Button Area css
============================================================================================ */