
.post_2{
    a:hover{
        color: $btn_bg !important;
    }
    img{
        width: 100%;
        height: auto;
    }
    .post_text_1{
        h3{
            color: #2a2a2a;
            line-height: 1.333;
            font-family: $font_stack_2;
            font-weight: 600;
            @include transform_time(0.5s);
            &:hover{
                color: $btn_bg;
            }
            @media #{$small_mobile}{
                
            }
            @media #{$large_mobile}{
                font-size: 20px;
            }
            @media #{$tab_device}{
            
            }
            @media #{$medium_device}{
            
            }
        }
        h5{
            font-size: 11px;
            color: $btn_bg;
            text-transform: uppercase;
            font-family: $font_stack_1;
            font-weight: 600;
            margin-bottom: 14px;
            @include transform_time(0.5s);
            @media #{$small_mobile}{
                margin-bottom: 10px;
            }
            @media #{$large_mobile}{
                margin-bottom: 10px;
            }
            @media #{$tab_device}{
            
            }
            @media #{$medium_device}{
            
            }
            &:hover{
                color: $btn_bg;
            }
        }
        p{
            font-size: 13px;
            color: $font_4;
            margin-top: 15px;
            font-weight: 600;
            @media #{$small_mobile}{
                margin-top: 10px;
            }
            @media #{$large_mobile}{
                margin-top: 10px;
            }
            @media #{$tab_device}{
            
            }
            @media #{$medium_device}{
            
            }
            span{
                font-size: 13px;
                font-weight: 800;
                color: $span_color;
                @include transform_time(0.5s);
            }
        }
    }
    
    .post_icon{
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #ecf0f2;
        @media #{$small_mobile}{
            margin-top: 10px;
            padding-top: 10px;
        }
        @media #{$large_mobile}{
            margin-top: 10px;
            padding-top: 10px;
        }
        @media #{$tab_device}{
            margin-top: 10px;
            padding-top: 10px;
        }
        @media #{$medium_device}{
            margin-top: 10px;
            padding-top: 10px;
        }
        ul{
            li{
                display: inline-block;
                text-transform: capitalize;
                position: relative;
                padding-left: 25px;
                margin-right: 20px;
                font-size: 13px;
                color: #888888;
                @media #{$small_mobile}{
                    padding-left: 24px;
                    margin-right: 12px;
                }
                @media #{$large_mobile}{
                    padding-left: 15px;
                    margin-right: 5px;
                }
                @media #{$tab_device}{
                
                }
                @media #{$medium_device}{
                    padding-left: 21px;
                    margin-right: 12px;
                }
                i{
                    padding-top: 5px;
                    margin-right: 10px;
                    position: absolute;
                    left: 0;
                    @media #{$small_mobile}{
                       font-size: 12px;
                    }
                    @media #{$large_mobile}{
                        font-size: 12px;
                    }
                    @media #{$tab_device}{
                    
                    }
                    @media #{$medium_device}{
                    
                    }
                }
            }
        }
    }
}  

.post_2{
    .category_post_img{
        position: relative;
        .category_btn{
            position: absolute;
            bottom: -5px;
            border-top-right-radius: 10px;
            padding: 15px 30px;
            background-color: $white_color;
            color: $btn_bg;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 600;
            display: inline-block;
        }
    }
    .post_text_1{
        margin: 21px 0px 0;
        @media #{$small_mobile}{
            margin: 20px 0 0 ;
        }
        @media #{$large_mobile}{
            margin: 20px 0 0 ;
        }
        @media #{$tab_device}{
            margin: 20px 0 0 ;
        }
        @media #{$medium_device}{
            margin: 20px 0 0;
        }
        p{
            font-size: 13px;
            color: $font_4;
            margin: 5px 0px 0px;
            span{
                font-size: 13px;
                font-weight: 800;
                color: $span_color;
            }
        }
    }
} 
.post_1{
    position: relative;
    text-align: center;
    margin-bottom: 30px;
  .single_post_text{
    padding: 40px 10px;
        display: inline-block;
        text-align: center;
        background-color: #fff;
        margin-top: -50px;
        max-width: 320px;
        @media #{$tab}{
            padding: 20px 10px;
        }
      a{
          h5{
              color: $base_color;
              margin-bottom: 17px;
          }
      }
      h2{
          margin-bottom: 21px;
          font-size: 24px;
          @media #{$tab}{
            margin-bottom: 10px;
        }
      }

  }  
}
.feature_post{
    .single_post_text{
        margin-top: -50px;
        max-width: 520px;
        padding: 50px 70px;
        @media #{$tab}{
            padding: 30px 15px;
        }
        h2{
            font-size: 30px;
            @media #{$tab}{
                font-size: 25px;
            }
        }
    }
}
.page_pageniation{
    .page-link{
        border: 0px solid transparent;
        color: $heading_color;
        font-size: 20px;
    }
}

