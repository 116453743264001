/**************** banner part css start ****************/
.banner_post{
    .col-lg-12, .col-xl-12, .col-sm-12{
        padding-right: 0px;
        padding-left: 0px;
    }
    .banner_post_bg_1{
        @include background("../img/banner_blog/banner_blog_1.png");
    }
    .banner_post_bg_2{
        @include background("../img/banner_blog/banner_blog_2.png");
    }
    .banner_post_bg_3{
        @include background("../img/banner_blog/banner_blog_3.png");
    }
    .h2{
        color: #2a2a2a;
        line-height: 1.333;
        font-family: $font_stack_2;
        font-weight: 600;
        @include transform_time(.5s);
        &:hover{
            color: $btn_bg !important;
        }
    }
    a{
        h2{
            &:hover{
                @include transform_time(.5s);
                color: $btn_bg !important;
            }
        }
    }
    h5{
        font-size: 15px;
        color: $btn_bg;
        text-transform: capitalize;
        font-family: $font_stack_1;
        font-weight: 500;
        margin-bottom: 12px;
        
    }
    p{
        font-size: 13px;
        color: $font_4;
        margin-top: 15px;
        span{
            font-size: 13px;
            font-weight: 800;
            color: $span_color;
        }
    }
    .banner_post_1{
        position: relative;
        height: 700px;
        width: 33%;
        float: left;
        margin: 0 auto;
        @media #{$small_mobile}{
            width: 100%;
            height: 400px;
            margin-bottom: 30px;
        }
        @media #{$large_mobile}{
            width: 48%;
            height: 400px;
            margin-bottom: 30px;
        }
        @media #{$tab_device}{
            width: 48%;
            height: 400px;
            margin-bottom: 30px;
        }
        @media #{$medium_device}{
            height: 400px;
            margin-bottom: 40px;
        }
        .banner_post_iner{
            padding: 45px;
            position: absolute;
            bottom: -150px;
            left: 0;
            right: 0;
            background-color: $white_color;
            max-width: 490px;
            margin: 0 auto;
            @media #{$small_mobile}{
                bottom: 0px;
                padding: 20px;
                max-width: 300px;
                h2{
                    font-size: 25px;
                }
            }
            @media #{$large_mobile}{
                bottom: 0px;
                padding: 20px;
                h2{
                    font-size: 25px;
                }
            }
            @media #{$tab_device}{
                bottom: 0px;
                padding: 20px;
                h2{
                    font-size: 25px;
                }
            }
            @media #{$medium_device}{
                padding: 20px;
                h2{
                    font-size: 25px;
                }
            }
        }
    }
}
  
/**************** hero part css end ****************/
